<template>
  <div class="result-page">
    <div class="result-row">
      <div class="statement-head">
        <div class="name"><i class="label"></i><span class="label-value">勤劳的小蜜蜂</span></div>
        <div class="head-desc">超过了全国 <span class="bold">99%</span> 的鱼宝儿</div>
      </div>
      <div class="statement-content">
        <div class="statement-income">
          <div class="income-desc">2022年总收入</div>
          <div class="income-line">....................</div>
          <div class="income-value">
            <i class="label"></i>
            <span class="label-value">88888888元</span>
          </div>
        </div>
        <div class="statement-income">
          <div class="income-desc">2022年总支出</div>
          <div class="income-line">....................</div>
          <div class="income-value">
            <i class="label"></i>
            <span class="label-value">88888888元</span>
          </div>
        </div>
        <div class="statement-top5">
          <p class="top5-value">2022年消费类TOP5</p>
          <div class="consume-row">
            <div class="consume-list"
            :class="{ no: index === 2 }"
              v-for="(item, index) in consumeList"
              :key="index">
              <span class="consume-frame"
                :style="{ backgroundColor: item.color }"></span>
              <p class="consume-name">{{item.name}}</p>
            </div>
          </div>
        </div>
        <div class="statement-desc">
          <p class="keywords-desc">你的2022年关键词</p>
          <div class="keywords-name">
            <i class="label"></i>
            <span class="label-value">坚守幸福</span>
          </div>
        </div>
        <!-- <div class="statement-btn" @click="shareAction">晒出我的账单</div> -->
      </div>
      <div class="share-bottom">
        <div class="share-row">
          <div class="share-frame">
            <div class="share-qrcode">
              <img
        src="//download-cos.yofish.com/yofish-gongjushiyebu/20221212111538137-qrcode.png" alt="" />
            </div>
            <p>查看你的有鱼记账之旅</p>
          </div>
          <div class="share-right">
            <div class="share-desc">
              <p>关注公众号<span class="bold">“有鱼记账Pro”</span></p>
              <p>更有好礼等你拿! ! !</p>
            </div>
            <div class="logo">
              <div class="image-logo">
                <img
          src="//download-cos.yofish.com/yofish-gongjushiyebu/20221212110714718-logo.png" alt="" />
              </div>
              <span>有鱼记账</span>
              <div class="frame"></div>
            </div>
          </div>
        </div>
        <div class="s-line"></div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'AnnualResult',
  data() {
    return {
      consumeList: [
        {
          name: '旅游出行',
          color: '#FF8519',
          value: 10,
        },
        {
          name: '旅游出行',
          color: '#FF4C7A',
          value: 30,
        },
        {
          name: '旅游出行',
          color: '#504CFF',
          value: 40,
        },
        {
          name: '旅游出行',
          color: '#00BAFF',
          value: 50,
        },
        {
          name: '旅游出行',
          color: '#1ADD68',
          value: 20,
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
@import './styles/result.scss';
</style>
